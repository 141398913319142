<template>
	<div class="home">
		<div class="hugeScore" :class="{'good ': score.score >= 0, 'bad ': score.score < 0}">{{ score.score }}</div>
		<div class="subHeader">{{ score.numChoices }} Picks, {{ score.numCorrect }} correct ({{ score.percentage }}%)</div>
		<div
				 class="group"
				 v-for="(group, index) in groups"
				 v-bind:key="'group' + index"
				 @click="goTo(group)"
				 :style="$services.hasKey('Community') ? 'cursor:pointer':''">
			<div v-if="group.myScore && group.myScore.score">
				<div class="groupScore right" :class="{'good ': group.myScore.score.score >= 0, 'bad ': group.myScore.score.score < 0}">{{ group.myScore.score.score }}</div>
				<div class="groupName">{{ group.name }}</div>
				<div class="groupSubHeader">
					{{ group.myScore.score.numChoices }} Picks,
					{{ group.myScore.score.numCorrect }} correct
					({{ group.myScore.score.percentage }}%)
				</div>
				<div class="leaderSection">
					{{ group.leaderText }}
					<br />
					{{ group.yourText }}
				</div>
			</div>
		</div>
		<div class="resultHeader">Recent Results</div>
		<choice-row :choice="choice" v-for="choice in results" v-bind:key="choice.id"></choice-row>
		<div class="bottom-spacer" />
	</div>
</template>

<script>
import ChoiceRow from '@/components/ChoiceRow.vue'

export default {
	name: "Basic",
	components: {
		ChoiceRow
	},
	props: ["id"],
	data() {
		return {
			results: [],
			groups: [],
			score: {score: 0}
		};
	},
	created() {
		this.loadData();
	},
	methods: {
		loadData: async function () {
			this.results = JSON.parse(localStorage.getItem('score-choice-list'));
			this.groups = JSON.parse(localStorage.getItem('score-group-list'));
			this.loadGroups();
			this.loadChoices();
			this.score = await this.$services.getOne("score", "");
		},

		loadGroups: async function () {
			let groups = await this.$services.getAll("groups");
			groups = groups.map(async (group) => {
				group["userScores"] = await this.$services.groupUserScores(group.id);
				if (group.userScores && group.userScores.length > 0) {
					const place = group.userScores.findIndex((score) => {return score.user.id == this.$services.me.id});
					if (place == -1) {
						group["yourText"] = `You're not yet ranked`
						group["myScore"] = {score: {score: 0, numChoices: 0, numCorrect: 0, percentage: 0}};
					} else {
						group["myScore"] = group.userScores[place];
						group["yourText"] = `You're ranked #${place + 1} out of ${group.userScores.length}`
					}
					if (place == 0) {
						group["leaderText"] = "You are the group expert!";
					} else {
						group["leaderText"] = group.userScores[0].user.username + " is the group expert with a score of " + group.userScores[0].score.score;
					}
				}
				return group;
			});
			Promise.all(groups).then(res => {
				this.groups = res;
				localStorage.setItem('score-group-list', JSON.stringify(this.groups));
			});
		},

		loadChoices: async function () {
			this.results = await this.$services.get("choices/settled");
			localStorage.setItem('score-choice-list', JSON.stringify(this.results));
		},
		goTo: async function (group) {
			if (this.$services.hasKey("Community")) {
				this.$router.push(`/group/${group.id}`)
			}
		}
	}
}
</script>

<style scoped>
.hugeScore {
	font-size: 55px;
	text-align: center;
	font-weight: 600;
}

.subHeader {
	font-size: 20px;
	text-align: center;
}

.group {
	background-color: black;
	color: #cccccc;
	display: block;
	clear: all;
	border: 2px solid green;
	border-radius: 4px;
	margin: 3px;
	padding: 3px;
}

.groupName {
	color: #99ccff;
	font-size: 22px;
}

.groupScore {
	font-size: 30px;
	font-weight: 600;
	padding: 3px;
	vertical-align: top;
	display: inline-block;
}

.groupSubHeader {
	font-size: 16px;
}

.leaderSection {
	font-size: 16px;
	margin-top: 5px;
	background-color: rgba(51, 153, 255, 0.2);
	border-radius: 5px;
	border: 1px solid black;
	padding: 2px;
}

.resultHeader {
	font-size: 22px;
	background: #444444;
	margin: 5px 0px 2px 0px;
	border-top: 1px solid yellow;
	color: white;
	padding: 2px;
}
</style>
